import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.fill.js";
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5777b054"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "s-canvas"
};
var _hoisted_2 = ["width", "height"];
import { onMounted, watch } from 'vue';
export default {
  __name: 'Sidentify',
  props: {
    identifyCode: {
      type: String,
      default: '1234'
    },
    fontSizeMin: {
      type: Number,
      default: 20
    },
    fontSizeMax: {
      type: Number,
      default: 35
    },
    backgroundColorMin: {
      type: Number,
      default: 180
    },
    backgroundColorMax: {
      type: Number,
      default: 240
    },
    colorMin: {
      type: Number,
      default: 50
    },
    colorMax: {
      type: Number,
      default: 160
    },
    lineColorMin: {
      type: Number,
      default: 40
    },
    lineColorMax: {
      type: Number,
      default: 180
    },
    dotColorMin: {
      type: Number,
      default: 0
    },
    dotColorMax: {
      type: Number,
      default: 255
    },
    contentWidth: {
      type: Number,
      default: 100
    },
    contentHeight: {
      type: Number,
      default: 30
    }
  },
  setup: function setup(__props) {
    var props = __props; // 生成一个随机数

    var randomNum = function randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    }; // 生成一个随机的颜色


    var randomColor = function randomColor(min, max) {
      var r = randomNum(min, max);
      var g = randomNum(min, max);
      var b = randomNum(min, max);
      return 'rgb(' + r + ',' + g + ',' + b + ')';
    }; // 绘制干扰线


    var drawLine = function drawLine(ctx) {
      for (var i = 0; i < 3; i++) {
        ctx.strokeStyle = randomColor(props.lineColorMin, props.lineColorMax);
        ctx.beginPath();
        ctx.moveTo(randomNum(0, props.contentWidth), randomNum(0, props.contentHeight));
        ctx.lineTo(randomNum(0, props.contentWidth), randomNum(0, props.contentHeight));
        ctx.stroke();
      }
    };

    var drawText = function drawText(ctx, txt, i) {
      ctx.fillStyle = randomColor(props.colorMin, props.colorMax);
      ctx.font = randomNum(props.fontSizeMin, props.fontSizeMax) + 'px SimHei';
      var x = (i + 1) * (props.contentWidth / (props.identifyCode.length + 1));
      var y = randomNum(props.fontSizeMax, props.contentHeight - 5);
      var deg = randomNum(-45, 45); // 修改坐标原点和旋转角度

      ctx.translate(x, y);
      ctx.rotate(deg * Math.PI / 180);
      ctx.fillText(txt, 0, 0); // 恢复坐标原点和旋转角度

      ctx.rotate(-deg * Math.PI / 180);
      ctx.translate(-x, -y);
    };

    var drawDot = function drawDot(ctx) {
      // 绘制干扰点
      for (var i = 0; i < 30; i++) {
        ctx.fillStyle = randomColor(0, 255);
        ctx.beginPath();
        ctx.arc(randomNum(0, props.contentWidth), randomNum(0, props.contentHeight), 1, 0, 2 * Math.PI);
        ctx.fill();
      }
    };

    var drawPic = function drawPic() {
      var canvas = document.getElementById('s-canvas');
      var ctx = canvas.getContext('2d');
      ctx.textBaseline = 'bottom'; // 绘制背景

      ctx.fillStyle = randomColor(props.backgroundColorMin, props.backgroundColorMax);
      ctx.fillRect(0, 0, props.contentWidth, props.contentHeight); // 绘制文字

      for (var i = 0; i < props.identifyCode.length; i++) {
        drawText(ctx, props.identifyCode[i], i);
      }

      drawLine(ctx);
      drawDot(ctx);
    };

    watch(function () {
      return props.identifyCode;
    }, function (newValue, oldValue) {
      drawPic();
    });
    onMounted(function () {
      drawPic();
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("canvas", {
        id: "s-canvas",
        width: props.contentWidth,
        height: props.contentHeight
      }, null, 8, _hoisted_2)]);
    };
  }
};