import "core-js/modules/es.json.stringify.js";
import { defineComponent, ref, onMounted } from "vue";
import { ElMessage } from 'element-plus';
import SIdentify from '../../components/Sidentify';
import API from '../../api/axios';
export default defineComponent({
  components: {
    SIdentify: SIdentify
  },
  setup: function setup() {
    var bannerList = ref([{
      url: require("../../assets/banner.jpg")
    } // {
    //   url: require("../../assets/banner2.jpg"),
    // },
    ]);

    var handleXiaochong = function handleXiaochong() {
      window.open('https://www.nbugs.com/');
    };

    var handleYilianju = function handleYilianju() {
      window.open('http://www.yilianju.cn/');
    };

    var handleObook = function handleObook() {
      window.open('http://obook.com.cn/');
    };

    var handleWF = function handleWF() {
      window.open('http://www.wf-tech.com/');
    };

    var adddialog = ref(false);

    var handleAdd = function handleAdd() {
      adddialog.value = true;
    };

    var handleClose = function handleClose() {
      adddialog.value = false;
      form.value = {
        name: '',
        phone: '',
        question: '',
        code: ''
      };
    };

    var form = ref({
      name: '',
      phone: '',
      question: '',
      code: ''
    });
    var rules = ref({
      name: [{
        required: true,
        message: '请输入姓名',
        trigger: 'blur'
      }],
      phone: [{
        required: true,
        message: '请输入联系方式',
        trigger: 'blur'
      }],
      question: [{
        required: true,
        message: '请输入需求',
        trigger: 'blur'
      }],
      code: [{
        required: true,
        message: '请输入验证码',
        trigger: 'blur'
      }]
    });
    var formRef = ref(null); // 验证码

    var identifyCodes = "1234567890";
    var identifyCode = ref('3212'); // 产生随机数

    var randomNum = function randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    }; // 制作验证码


    var makeCode = function makeCode(o, l) {
      for (var i = 0; i < l; i++) {
        identifyCode.value += o[randomNum(0, o.length)];
      }
    }; // 刷新验证码


    var refreshCode = function refreshCode() {
      identifyCode.value = "";
      makeCode(identifyCodes, 4);
    };

    onMounted(function () {
      identifyCode.value = "";
      makeCode(identifyCodes, 4);
    }); // 发送按钮

    var sendForm = function sendForm() {
      // console.log("----------",identifyCode.value);
      formRef.value.validate(function (valid) {
        if (valid) {
          if (identifyCode.value == form.value.code) {
            // console.log('成功');
            var suggest_content = JSON.stringify(form.value);
            API({
              // url: 'https://picbook.bcepd.cn/d-bcepd/easyj/suggests/free/add',
              url: 'https://bcepd-dev.zucc.easyj.top/d-bcepd/easyj/suggests/free/add',
              method: 'POST',
              params: {
                orgid: '000001',
                suggest_type: 'bcepd_demand',
                suggest_content: suggest_content
              }
            }).then(function (res) {
              if (res.data.code == 0) {
                ElMessage.success('发送成功');
                adddialog.value = false;
                form.value = {
                  name: '',
                  phone: '',
                  question: '',
                  code: ''
                };
              } else {
                ElMessage.error("".concat(res.data.msg));
              }
            });
          } else {
            ElMessage.error('验证码输入错误');
            refreshCode();
          }
        } else {
          return false;
        }
      });
    };

    var handlebackTop = function handlebackTop() {
      content.scrollIntoView();
    };

    onMounted(function () {
      handlebackTop();
    });
    return {
      bannerList: bannerList,
      adddialog: adddialog,
      form: form,
      handleAdd: handleAdd,
      handleClose: handleClose,
      identifyCode: identifyCode,
      refreshCode: refreshCode,
      sendForm: sendForm,
      rules: rules,
      formRef: formRef,
      handleXiaochong: handleXiaochong,
      handleYilianju: handleYilianju,
      handleObook: handleObook,
      handleWF: handleWF,
      handlebackTop: handlebackTop
    };
  }
});